var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm._v(
            " Transactions - " +
              _vm._s((_vm.currentPage - 1) * _vm.perPage + 1) +
              " - " +
              _vm._s(_vm.currentPage * _vm.perPage) +
              " of " +
              _vm._s(_vm.totalRows) +
              " "
          ),
          _c("b-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            attrs: { small: "", label: "Spinning" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.error && !_vm.statusMsg && _vm.totalRows == 0,
              expression: "!error && !statusMsg && totalRows == 0"
            }
          ]
        },
        [_vm._v(" No data available ")]
      ),
      _vm.error ? _c("div", [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e(),
      _vm.statusMsg
        ? _c("div", [_vm._v(" " + _vm._s(_vm.statusMsg) + " ")])
        : _vm._e(),
      _c(
        "b-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.error && !_vm.statusMsg && _vm.totalRows > 0,
              expression: "!error && !statusMsg && totalRows > 0"
            }
          ],
          staticClass: "p-0",
          attrs: { fluid: "" }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "12", lg: "8", xl: "6" }
                },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.collapse-1",
                          modifiers: { "collapse-1": true }
                        }
                      ],
                      staticClass: "mr-4",
                      attrs: { variant: "primary" }
                    },
                    [_vm._v("Configure columns")]
                  ),
                  _c("span", { staticClass: "mr-2" }, [_vm._v("Sort by:")]),
                  _c(
                    "b-button-group",
                    { staticClass: "mr-4" },
                    [
                      _c(
                        "b-button",
                        {
                          class: { active: _vm.orderBy == "selected" },
                          on: {
                            click: function($event) {
                              _vm.orderBy = "selected"
                            }
                          }
                        },
                        [_vm._v(" Selected Columns ")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: { active: _vm.orderBy == "quantity" },
                          on: {
                            click: function($event) {
                              _vm.orderBy = "quantity"
                            }
                          }
                        },
                        [_vm._v(" Quantity ")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: { active: _vm.orderBy == "providerIncome" },
                          on: {
                            click: function($event) {
                              _vm.orderBy = "providerIncome"
                            }
                          }
                        },
                        [_vm._v(" Provider Income ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "mb-4",
                  attrs: { cols: "12", md: "12", lg: "4", xl: "4" }
                },
                [
                  _c("span", { staticClass: "mr-2" }, [_vm._v("Export type:")]),
                  _c(
                    "b-button-group",
                    { staticClass: "mr-4" },
                    [
                      _c(
                        "b-button",
                        {
                          class: { active: _vm.exportType == "xlsx" },
                          on: {
                            click: function($event) {
                              _vm.exportType = "xlsx"
                            }
                          }
                        },
                        [_vm._v(" Excel ")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: { active: _vm.exportType == "csv" },
                          on: {
                            click: function($event) {
                              _vm.exportType = "csv"
                            }
                          }
                        },
                        [_vm._v(" CSV ")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: { active: _vm.exportType == "tab" },
                          on: {
                            click: function($event) {
                              _vm.exportType = "tab"
                            }
                          }
                        },
                        [_vm._v(" TAB ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { attrs: { id: "exportWrapper" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            variant: "primary",
                            disabled: _vm.exportBusy
                          },
                          on: { click: _vm.exportTable }
                        },
                        [_vm._v("Export")]
                      )
                    ],
                    1
                  ),
                  _c("b-spinner", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.exportBusy,
                        expression: "exportBusy"
                      }
                    ],
                    attrs: { small: "", label: "Spinning" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-collapse",
                    {
                      staticClass: "mt-2",
                      attrs: { id: "collapse-1" },
                      model: {
                        value: _vm.configureColumnsVisible,
                        callback: function($$v) {
                          _vm.configureColumnsVisible = $$v
                        },
                        expression: "configureColumnsVisible"
                      }
                    },
                    [
                      _c("b-card", [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("h4", [_vm._v("Selected")]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group draggable-group",
                                  attrs: {
                                    list: _vm.selectedFields,
                                    group: "fields"
                                  },
                                  on: { change: _vm.refresh }
                                },
                                _vm._l(_vm.selectedFields, function(element) {
                                  return _c(
                                    "div",
                                    {
                                      key: element,
                                      staticClass:
                                        "list-group-item draggable-item"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.fieldLabels[element]) +
                                          " "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("h4", [_vm._v("Available")]),
                              _c(
                                "draggable",
                                {
                                  staticClass: "list-group draggable-group",
                                  attrs: {
                                    list: _vm.availableFields,
                                    group: "fields"
                                  }
                                },
                                _vm._l(_vm.availableFields, function(element) {
                                  return _c(
                                    "div",
                                    {
                                      key: element,
                                      staticClass:
                                        "list-group-item draggable-item"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.fieldLabels[element]) +
                                          " "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-4" }, [
                            _c("h4", [_vm._v("Instructions")]),
                            _c("p", [
                              _vm._v(
                                ' Drag and drop columns from "available" to "selected" and vice versa to get the preferred columns. You can also use dragging and dropping to select the order of the columns. '
                              )
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.totalRows > _vm.perPage &&
                    _vm.configureColumnsVisible == false,
                  expression:
                    "totalRows > perPage && configureColumnsVisible == false"
                }
              ]
            },
            [
              _c(
                "b-col",
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      align: "fill",
                      size: "sm"
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.configureColumnsVisible == false && _vm.columnsInitialized == true
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        attrs: {
                          small: "",
                          striped: "",
                          hover: "",
                          id: "royalty-transaction-table",
                          items: _vm.provider,
                          fields: _vm.fields,
                          "current-page": _vm.currentPage,
                          "per-page": _vm.perPage
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "head()",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.fieldLabels[data.column]) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "head(quantity)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-right" }, [
                                    _vm._v("Quantity")
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(grossIncome)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-right" }, [
                                    _vm._v("Income")
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(grossIncomeUsd)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-right" }, [
                                    _vm._v("Income USD")
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(grossIncomeGbp)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-right" }, [
                                    _vm._v("Income GBP")
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(providerIncome)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-right" }, [
                                    _vm._v("Provider Income")
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "head(distIncome)",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-right" }, [
                                    _vm._v("THSO Share Royalty")
                                  ])
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "cell(accountingPeriod)",
                              fn: function(data) {
                                return [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatAccountingPeriod")(
                                          data.item.accountingPeriod
                                        )
                                      )
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "cell(track)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.$emit("item-click", {
                                            type: "track",
                                            item: {
                                              key: data.item.trackReportingCode,
                                              title: data.item.track
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.track) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(album)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.$emit("item-click", {
                                            type: "album",
                                            item: {
                                              key: data.item.albumReportingCode,
                                              title: data.item.album
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.album) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(attribution)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.$emit("item-click", {
                                            type: "attribution",
                                            item: {
                                              key: data.item.attribution,
                                              title: data.item.attribution
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.attribution) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(reference)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.$emit("item-click", {
                                            type: "reference",
                                            item: {
                                              key: data.item.reference,
                                              title: data.item.reference
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.reference) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(outlet)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.$emit("item-click", {
                                            type: "outlet",
                                            item: {
                                              key: data.item.outletId,
                                              title: data.item.outlet
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.outlet) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(territory)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.$emit("item-click", {
                                            type: "country",
                                            item: {
                                              key: data.item.territory,
                                              title:
                                                _vm.$root.$data.countries[
                                                  data.item.territory
                                                ]
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$root.$data.countries[
                                              data.item.territory
                                            ]
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(quantity)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.quantity,
                                            0,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(grossIncome)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.grossIncome,
                                            2,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(grossIncomeUsd)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.grossIncomeUsd,
                                            2,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(grossIncomeGbp)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.grossIncomeGbp,
                                            2,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(providerIncome)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.providerIncome,
                                            2,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(distIncome)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-right",
                                      staticStyle: {
                                        "font-family":
                                          "'Roboto Mono', monospace"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberFormat")(
                                            data.item.distIncome,
                                            2,
                                            _vm.numberLocale
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1618577094
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }